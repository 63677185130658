import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  facebook,
  gallery1,
  moreblog1,
  moreblog2,
  moreblog3,
  playicon,
  whatsapp,
  twitterblog,
  copy,
  blog8BannerInner,
} from "../../images";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Link } from "react-router-dom";
//utils
import { reduceDescSize } from "../../helpers/utils";

import "./BlogInner8.scss";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const BlogInner8 = () => {
  const { width } = useWindowDimensions();
  const [copied, setCopied] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const blogDateArr = [
    {
      date: "June 2, 2022",
      category: "Lorem Ipsum",
      company: "Sanathan",
    },
  ];

  const blogDescArr = [
    {
      id: 1,
      heading:
        "Craft Your Festive Wardrobe with Sanathan Textile Yarns for Blazers, Suits, Kurtas, and Sarees",
      desc1: `
      As the festive season approaches, the demand for ethnic/Indian outfits skyrockets, making it the perfect time to enhance your offerings with fancy textile yarns. At Sanathan Textiles, we specialize in providing fancy yarns that empower customers to create elegant blazers, suits, timeless kurtas, beautiful sarees & sherwani ensuring your collections are ready for the festive seasons.
      <br/><br/>
      <strong>Harness the Joy of Festivals</strong>
      <br/><br/>
      Festivals are pivotal in Indian culture, driving community engagement and consumer enthusiasm for new clothing. <a href="https://www.sanathan.com/">By partnering</a>, with Sanathan Textiles, you can enhance your product lines with yarns designed for festive fabrics that embody tradition, elegance, and modern appeal. Our diverse range of yarns allows you to create fabric and garments that cater to various customer preferences, from classic to contemporary styles.
      <br/><br/>
      <strong>Kurtas and Sarees: The Heart of Festive Fashion</strong>
      <br/><br/>
      For many, festivals like Diwali are synonymous with traditional clothing, particularly kurtas and sarees. Our Catonic Dyeable Polyester yarns and <a href="https://www.sanathan.com/products/born-dyed">Born Dyed</a> colour yarns offer exceptional options for producing these fabrics.
      <br/><br/>
      <strong>Catonic Dyeable Yarns: </strong>
      <br/><br/>
      These versatile yarns can be dyed in a spectrum of vibrant colors, allowing you to meet the specific demands of your customers. They are perfect for designing kurtas and sarees that reflect festive themes. Their special properties ensure that your creations maintain its vibrancy and quality, making them appealing for festive wear.
      <br/><br/>
      <strong>Born Dyed Yarns: </strong>
      <br/><br/>
      Eco-friendly long-lasting colors yarns, are perfect for producing garments that embody the essence of celebration. With deep hues that resist fading, imagine creating sarees in striking emerald green or kurtas in bold blues, catering to customers seeking elegance and sophistication.
      <br/><br/>
      <strong>Blazers and Suits: Modern Festive Offerings</strong>
      <br/><br/>
      Blazers and suits are essential items for festive occasions and events. Our yarns are ideal for producing tailored blazers and suits that blend modern aesthetics with festive charm:
      <br/><br/>
      <strong>Puro Cotton Yarns:</strong>
      <br/><br/>
      Soft and breathable, our puro 100% <a href="https://www.sanathan.com/products/cotton-yarn">cotton yarns</a> provide the perfect solution for crafting comfortable yet stylish clothes. These high-quality yarns ensure that your fabrics stand out in the competitive market, meeting the needs of clients looking for polished, sophisticated options for festive gatherings.
      <br/><br/>
      <strong>Why Partner with Sanathan Textiles?</strong>
      <br/><br/>
      <strong>Quality Assurance:</strong> Our yarns are crafted to meet high standards, ensuring your finished garments are of exceptional quality.
      <br/><br/>
      <strong>Versatile Options:</strong> With a wide variety of yarns available, you can create diverse collections that cater to different segments of the market.
      <br/><br/>
      <strong>Timely Delivery:</strong> We understand the urgency of the festive season, and our efficient supply chain ensures that you receive your orders on time.
      <br/><br/>
      <strong>Collaborative Approach:</strong> We work closely with our clients to understand their specific needs, providing tailored solutions that help you stand out in the market.
      <br/><br/>
      <strong>Dress Up for the Festivities</strong>
      <br/><br/>
      As the festive season approaches, now is the perfect time to start planning your wardrobe. With Sanathan Textiles, you’ll have access to <a href="https://www.sanathan.com/products">high-quality yarns</a> that help you create outfits that not only look incredible but also feel great to wear. Whether you're designing a traditional kurta or a modern blazer, our yarns provide the foundation for a wardrobe that celebrates both style and comfort.
      <br/><br/>
      At Sanathan Textiles, we’re committed to delivering textile innovations that meet the needs of modern fashion while honoring the traditional roots of festive celebrations. Our extensive range of yarns offers endless possibilities, whether you’re looking to create a statement piece or a timeless outfit.
      <br/><br/>
      This festive season, let your wardrobe reflect the joy and spirit of the celebrations. From vibrant sarees to sharp suits and sherwani, Sanathan Textiles has a wide variety of yarns to fulfill your needs. Explore our collection today and craft a festive wardrobe that truly shines.
      <br/><br/>
      With Sanathan Textiles, the festivities come alive in every Thread!
      `,
    },
  ];

  const moreblogsArr = [
    {
      img: moreblog1,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog3,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
  ];

  const galleryArr = [
    {
      id: 1,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 2,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 3,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 4,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
  ];

  useEffect(() => {
    var gallery_swiper_container = new Swiper(
      ".swiper-container.gallery_swiper_container",
      {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          prevEl: ".bloginner_sec2 .left_arrow",
          nextEl: ".bloginner_sec2 .right_arrow",
        },
        breakpoints: {
          767: {
            slidesPerView: 1.6,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 2.7,
            spaceBetween: 10,
          },
        },
        // loop: true,
        // centeredSlides: true,
        // loopedSlides: thumbArr.length, //how many slides it should duplicate
      }
    );
    var moreblogs_swiper_container = new Swiper(
      ".swiper-container.moreblogs_swiper_container",
      {
        slidesPerView: 3,
        spaceBetween: 40,
        loop: false,
        navigation: {
          prevEl: ".bloginner_sec3 .left_arrow",
          nextEl: ".bloginner_sec3 .right_arrow",
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          767: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      }
    );
  });

  useEffect(() => {
    if (copied) {
      let copiedTimeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => {
        clearTimeout(copiedTimeout);
      };
    }
  }, [copied]);

  const dateList = blogDateArr.map((item) => (
    <>
      <p className="blog_date">{item.date} | </p>
      <p className="blog_category"> {item.category} </p>
      <p className="blog_company">| {item.company}</p>
    </>
  ));

  const descList = blogDescArr.map((item) => (
    <>
      <p
        className="blog_desc"
        dangerouslySetInnerHTML={{ __html: item.desc1 }}
      />
    </>
  ));

  const galleryVideoImageList = galleryArr.length
    ? galleryArr.map((item, i) => (
        <div className="swiper-slide" key={i}>
          {item.type == "video" ? (
            <>
              <div
                className="video_wrapper_div"
                onClick={() => handleImageWrapperClick(item.ytLink)}
              >
                <div className="content">
                  <img
                    src={item.image}
                    alt="Sanathan video gallery img"
                    loading="lazy"
                    className="gallery_img"
                  />
                  <div className="content-overlay"></div>
                  <img
                    src={playicon}
                    alt="Sanathan coloredArrow img"
                    loading="lazy"
                    className="playicon"
                    onClick={() => handleImageWrapperClick(item.ytLink)}
                  />
                  {/* <h2 className="galleryImageName">{item.title}</h2> */}
                </div>

                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          ) : (
            <>
              <div className="content">
                <img
                  src={item.image}
                  alt="Sanathan blog gallery img"
                  loading="lazy"
                  className="gallery_img"
                />
                <div className="content-overlay"></div>
                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          )}
        </div>
      ))
    : null;

  const moreblogsList = moreblogsArr.map((item, i) => (
    <>
      <li className="swiper-slide moreblog_detail_wrapper">
        <Link to="/blog-inner" key={i}>
          <div className="imageanimate zoom-out">
            <img
              src={item.img}
              alt="Sanathan our blog img"
              loading="lazy"
              className="moreblog_img"
            />
            {/* <div className="content-overlay"></div> */}
          </div>
          <div className="text_container">
            <h1 className="moreblog_date">{item.date}</h1>
            <div className="verticle_line"> | </div>
            <h1 className="moreblog_category"> {item.category}</h1>
            <h1 className="moreblog_name">{item.title}</h1>
            <p className="moreblog_synopsis">
              {reduceDescSize(item.desc, 100)}
            </p>
            <div className="moreblog_cta_wrapper"></div>
          </div>
        </Link>
      </li>
    </>
  ));

  const handleImageWrapperClick = (videoLink) => {
    setToggler(!toggler);
    setVidLink(videoLink);
  };

  const copyUrl = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="Festive Fashion: Sanathan Textile Yarns for Stylish Blazers, Suits, Kurtas, and Sarees"
        metaDesc="Prepare for the festive season with Sanathan Textile's luxurious yarns for blazers, suits, kurtas, and sarees. Discover fabrics that combine durability, comfort, and style for every celebration."
      />
      <section className="bloginner_sec1" data-aos="fade-in">
        <div className="wrapper">
          <img
            className="mainimg"
            src={blog8BannerInner}
            alt="Sanathan blog inner img"
          />
          <div className="row social_icon_date">
            <div className="col-md-6 col-lg-4 datecat_list">
              {/* {dateList} */}
            </div>
            <div className="col-md-1 col-lg-4"></div>
            <div className="col-md-5 col-lg-4">
              {width < 767 ? <h2 className="share_title">Share</h2> : null}
              <div className="social_main_wrapper">
                <ul className="social_icons">
                  {width > 767 ? (
                    <li className="social_icon_li">
                      <h2 className="share_title">Share</h2>
                    </li>
                  ) : null}
                  <li className="social_icon_li">
                    <FacebookShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={facebook}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </FacebookShareButton>
                  </li>
                  <li className="social_icon_li">
                    <WhatsappShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={whatsapp}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </WhatsappShareButton>
                  </li>
                  <li className="social_icon_li">
                    <TwitterShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={twitterblog}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </TwitterShareButton>
                  </li>
                  <li
                    className="social_icon_li social_icon_li_copy"
                    onClick={copyUrl}
                  >
                    <span className="social_icon">
                      <img
                        src={copy}
                        alt="Sanathan copy url logo"
                        loading="lazy"
                      />
                    </span>
                    <span className="social_name">
                      {!copied ? "" : "Copied!"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="horizontal_line"></div>
          <h1 className="blog_inner_name">
            Craft Your Festive Wardrobe with Sanathan Textile Yarns for Blazers,
            Suits, Kurtas, and Sareeses
          </h1>
          <div>{descList}</div>
        </div>
      </section>
    </>
  );
};

export default BlogInner8;

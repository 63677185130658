import { useEffect } from "react";
import "./Newsroom.scss";

import Dash from "../../components/Dash/Dash";

import {
  NewsroomBannerImg,
  TextilePDF,
  magzinecover,
  exportcover,
  indiarating,
} from "../../images";

//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import { Link } from "react-router-dom";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);

const Newsroom = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // var article_slider_wrapper = new Swiper(
    //     ".swiper-container.article_slider_wrapper",
    //     {
    //         slidesPerView: 3,
    //         spaceBetween: 67,
    //     }
    // );

    var photos_slider_wrapper = new Swiper(
      ".swiper-container.photos_slider_wrapper",
      {
        autoplay: {
          delay: 2000,
        },
        loop: true,
        loopedSlides: 2,
        slidesPerView: 1.6,
        spaceBetween: 56,
        allowTouchMove: false,
        breakpoints: {
          0: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.2,
            spaceBetween: 40,
          },
        },
        navigation: {
          nextEl: ".navigations .right_arrow",
          prevEl: ".navigations .left_arrow",
        },
      }
    );
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/newsroom"
        metaTitle="News Room | Sanathan Textiles"
        metaDesc="Read all about our PR, media and organic articles that Sanathan Textiles have been featured in."
      />
      <section className="newsroom">
        <div className="wrapper">
          <div className="breadcrumb_styles"><Link to="/" className="breadcrumb_link">Home</Link> {">"} Newsroom</div>
          <div className="left_section">
            <h1 className="hollow_title">Newsroom</h1>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <p>
              What’s happening at Sanathan Textiles? Explore our latest news and
              stories covering innovation, products, our people, sustainability,
              marketing and more…
            </p>
          </div>
          <figure className="bg_img bg_img_desktop">
            <img src={NewsroomBannerImg} alt="sanathan img" />
          </figure>
        </div>
      </section>
      <figure className="bg_img bg_img_mobile wrapper">
        <img src={NewsroomBannerImg} alt="sanathan img" />
      </figure>

      <section className="article">
        <div className="wrapper">
          <div className="hollow_title">Article</div>
          <div className="article_slider_wrapper">
            <ul>
              <li>
                <a href={TextilePDF} target="_blank" rel="noreferrer">
                  <figure>
                    <img src={magzinecover} alt="sanathan img" />
                  </figure>
                  <div className="article_content">
                    <span>12 April 2022</span>
                    <p>The Textile Magazine</p>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://indiantextilejournal.com/new-demand-created-by-china-plus-one-is-here-to-stay/"
                  target="_blank" rel="noreferrer"
                >
                  <figure>
                    <img src={exportcover} alt="sanathan img" />
                  </figure>
                  <div className="article_content">
                    <span>1 April 2022</span>
                    <p>New Demand Created By China Plus One Is Here To Stay</p>
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://www.indiaratings.co.in/PressRelease?pressReleaseID=56924&title=India-Ratings-Assigns-Sanathan-Textiles-%E2%80%98IND-A-%E2%80%99%2FStable"
                  target="_blank" rel="noreferrer"
                >
                  <figure>
                    <img src={indiarating} alt="sanathan img" />
                  </figure>
                  <div className="article_content">
                    <span>09 December 2021</span>
                    <p>
                      India Ratings Assigns Sanathan Textiles ‘IND A-’/Stable
                    </p>
                  </div>
                </a>
              </li>
              {/* <li>
                <a
                  href="https://www.tribuneindia.com/news/ludhiana/132-acre-industrial-park-coming-up-in-wazirabad-409963"
                  target="_blank" rel="noreferrer"
                >
                  <figure>
                    <img src={wazirabad} alt="sanathan img" />
                  </figure>
                  <div className="article_content">
                    <span>06 July 2022</span>
                    <p>
                    132-acre industrial park coming up in Wazirabad
                    </p>
                  </div>
                </a>
              </li> */}
              {/* <li>
                <a
                  href="https://cfo.economictimes.indiatimes.com/news/how-cfos-of-thomas-cook-sanathan-textiles-and-yokohama-india-are-using-smart-technologies-to-enable-finance-function/98436287"
                  target="_blank" rel="noreferrer"
                >
                  <figure>
                    <img src={cfo} alt="sanathan img" />
                  </figure>
                  <div className="article_content">
                    <span>07 March 2023</span>
                    <p>
                    How CFOs of Thomas Cook, Sanathan Textiles, and Yokohama India are using smart technologies to enable finance function
                    </p>
                  </div>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </section>

      {/* <section className="photos">
        <div className="wrapper"></div>
        <div className="photos_slider_wrapper swiper-container">
          <div className="photos_slider_heading_section">
            <div className="left_section">
              <div className="hollow_title">Photos</div>
              <h3>Life at Sanathan Textiles</h3>
            </div>
            <div className="navigations navigations_desktop">
              <button className="left_arrow">Previous</button>
              <button className="right_arrow">Next</button>
            </div>
          </div>
          <ul className="swiper-wrapper">
            <li className="swiper-slide">
              <img src={NewsroomPhotos1} alt="sanathan img" />
            </li>
            <li className="swiper-slide">
              <img src={NewsroomPhotos2} alt="sanathan img" />
            </li>
          </ul>
        </div>*/}
      {/* <div className="navigations navigations_mobile">
          <button className="left_arrow">Previous</button>
          <button className="right_arrow">Next</button>
        </div> */}
      {/* </section> */}
    </>
  );
};

export default Newsroom;

import { useEffect, useState } from "react";
import "../ProductsInner.scss";
import "./technical.scss";
import "../style.scss";

import {
  blueArrow,
  dryCoolPdf,
  Technicaltext,
  Technicaltext1,
  Technicaltext2,
  Technicaltext3,
} from "../../../images";
import Dash from "../../../components/Dash/Dash";
import ProductsPopup from "../../../components/ProductsPopup/ProductsPopup";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const TechnicalYarn = () => {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const currentURL = window.location.href;

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={currentURL}
        metaTitle="Industrial Yarns for Technical Textile  | Sanathan Textiles"
        metaDesc="Discover durable industrial yarns for technical textiles, including industrial stitching thread, industrial ropes etc. Ideal for various high-performance applications. Explore now!"
      />

      {isModalOpen && <ProductsPopup onRequestClose={toggleModal} />}
      <section className="products_inner">
        <div className="wrapper">
          <div className="breadcrumb_styles">
            Products {">"} Yarns for Technical Textile & Industrial application
          </div>
          <div className="left_section">
            <p className="hollow_title">Products</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <h1 className="yarns">Yarns for Technical Textile</h1>
            <h4 className="capacity">9000 MTPA Manufacturing capacity</h4>
            <p>
              Yarns for technical textiles and industrial uses: Technical
              textiles are a niche segment with applications across agriculture,
              roads, railway tracks, sportswear, health on one hand and bullet
              proof jackets, fire proof jackets, high altitude combat gear and
              space applications on the other.
              <br />
              Technical textiles have been slowly but steadily gaining ground
              due to one or more of the reasons such as: functional requirement,
              health & safety; cost effectiveness; durability; high strength;
              light weight; versatility; customization; user friendliness; eco
              friendliness; logistical convenience etc. (Source: CRISIL Report).
            </p>
            {/* <img src={coloredArrow} className="arrow" alt="sanathan img"/> */}
            {/* <div className="bottom_arrow_container broch_link_wrap">
              <a
                href={dryCoolPdf}
                target="_blank"
                rel="noreferrer"
                className="broch_link"
              >
                Download Brochure
                <img
                  className="right_arrow"
                  src={blueArrow}
                  alt="sanathan img"
                />
              </a>
            </div> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={Technicaltext} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="products_inner_watch_now">
        <div className="wrapper">
          <div className="hollow_title">Watch Now</div>
          {/* <div className="video_section">
            <a>
              <img src={youtube_thumb} className="video_image" alt="sanathan img"/>
              <img
                src={playicon}
                className="playicon"alt="sanathan img"
                onClick={() =>
                  handleProjectVideoClick(
                    "https://www.youtube.com/embed/a6y1xzN1WD8"
                  )
                }
              />
            </a>
          </div> */}

          <figure className="for_desk">
            <iframe
              width="100%"
              height="680"
              src="https://www.youtube.com/embed/YT4Dhl_uHd8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure>
          <figure className="for_mob">
            <iframe
              width="100%"
              height="150"
              src="https://www.youtube.com/embed/YT4Dhl_uHd8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure>
        </div>
      </section>

      <section className="products_inner_list">
        <div className="wrapper">
          <div className="products_inner_list_heading_wrapper">
            <div className="hollow_title">Manufacturing Unit</div>
            {/* <h3>
              Click on any product to view <br /> detailed information
            </h3> */}
          </div>
          <div className="products_inner_listing">
            <div className="product_box">
              <figure>
                <img src={Technicaltext1} alt="sanathan img" />
              </figure>
              {/* <button className="tech_btn" onClick={toggleModal}>
                Partially Oriented Yarn
              </button> */}
            </div>
            <div className="product_box">
              <figure>
                <img src={Technicaltext2} alt="sanathan img" />
              </figure>
              {/* <button className="tech_btn" onClick={toggleModal}>
                Fully Drawn Yarn
              </button> */}
            </div>
            <div className="product_box">
              <figure>
                <img src={Technicaltext3} alt="sanathan img" />
              </figure>
              {/* <button className="tech_btn" onClick={toggleModal}>
                Draw Textured/Texturized Yarn
              </button> */}
            </div>
          </div>
        </div>
      </section>

      <section className="technical_sec3">
        <div className="wrapper">
          <h3>
            Some Applications Of Sanathan Yarns For Technical Textiles &
            Industrial Yarns End-Use Are Used For:
          </h3>
          <div className="row">
            <div className="col-lg-5">
              <ul>
                <li>
                  The Geogrid Fabric Used In The Building Of Roads And Dams
                </li>
                <li>
                  Automotive Applications Like Airbags, Upholstery And Seat
                  Belts
                </li>
                <li>Coating Application For Flex Banner Fabric</li>
              </ul>
            </div>
            <div className="col-lg-3">
              <ul>
                <li>Industrial Stitching Thread</li>
                <li>Hose Pipes</li>
                <li>Firefighting Equipment</li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>Electrical Wires And Optical Cables</li>
                <li>Nets For Fishing And Aquaculture</li>
                <li>Industrial Ropes</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TechnicalYarn;

import { useEffect, useState } from "react";
import "./OurSetupCertifications.css";

import Dash from "../../components/Dash/Dash";

import {
  CertificateBannerImage1,
  CertificateBannerImage2,
  GRSCert02,
  GRSCert01,
} from "../../images";

//swiper imports
import Swiper, {
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
} from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";
import useWindowDimensions from "../../hooks/useWindowDimensions";

Swiper.use([
  Navigation,
  Pagination,
  Autoplay,
  Thumbs,
  EffectFade,
  Parallax,
  Mousewheel,
  Controller,
]);
const certArr = [
  {
    certImg: GRSCert01,
  },
  {
    certImg: GRSCert02,
  },
  // {
  //   certImg: iso14001,
  // },
  // {
  //   certImg: iso45001,
  // },
  // {
  //   certImg: FirstCertificate,
  // },
  // {
  //   certImg: OEKOTEXcertificate,
  // },
];
const OurSetupCertifications = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);

    var certifications_slider_wrapper = new Swiper(
      ".swiper-container.certifications_slider_wrapper",
      {
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false,
        // },
        loop: false,
        // loopedSlides: 4,
        breakpoints: {
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
          767: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
          375: {
            slidesPerView: 1.3,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: ".navigations .right_arrow",
          prevEl: ".navigations .left_arrow",
        },
      }
    );
  }, []);

  const [isModalOpen, setModalIsOpen] = useState();
  const [imgCert, setImgCert] = useState("");
  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  const certList = certArr.map((cert, i) => (
    <li className="swiper-slide" key={i}>
      <figure onClick={toggleModal}>
        <img
          src={cert.certImg}
          onClick={() => setImgCert(cert.certImg)}
          alt="sanathan img"
        />
      </figure>
    </li>
  ));
  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/our-setup/certifications"
        metaTitle="Our Certifications | Sanathan Textiles"
        metaDesc="Explore our setup of globally recognized certifications at Sanathan Textiles. Our commitment to quality & industry standards is reflected in our certified processes. Discover more!"
      />

      {isModalOpen && (
        <div className="certificates_modal">
          <div className="certificates_modal_popup">
            <div className="close_button">
              <button
                type="button"
                onClick={toggleModal}
                className="btn-close cert_close"
              >
                <span className="icon-cross"></span>
              </button>
            </div>
            <div className="modal_data">
              <img src={imgCert} alt="sanathan img" />
            </div>
          </div>
        </div>
      )}
      <section className="our_setup_certifications">
        <div className="wrapper" data-aos="fade-in">
          <div className="breadcrumb_styles">Our Setup {">"} Certifications</div>
          <div className="left_section">
            <p className="hollow_title">Our Setup</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <h1 className="Certification">Certifications</h1>
            <p>
              Following the industrial processes as well as constant internal
              and external audits is a standard work practice at Sanathan
              Textiles.
            </p>
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={CertificateBannerImage2} alt="sanathan img" />
            </figure>
            <figure className="fg_img">
              <img src={CertificateBannerImage1} alt="sanathan img" />
            </figure>
          </div>
        </div>
        <div className="cerfitications_main_slider_wrapper">
          <div
            className="certifications_slider_wrapper swiper-container"
            data-aos="fade-up"
          >
            <div className="certifications_slider_heading_section">
              {/* <div className="hollow_title">Our Certifications</div> */}
              <div className="certifications_slider_heading_section_desktop"></div>
              <div className="certifications_slider_heading_section_mobile"></div>
              {/* <div className="navigations certifications_slider_heading_section_desktop">
                <button className="left_arrow">Previous</button>
                <button className="right_arrow">Next</button>
              </div> */}
            </div>
            <ul className="swiper-wrapper">{certList}</ul>
            {width <= 767 && (
              <div className="navigations certifications_slider_navigation_heading_section_mobile">
                <button className="left_arrow">Previous</button>
                <button className="right_arrow">Next</button>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurSetupCertifications;

import { useEffect, useState } from "react";
import "../ProductsInner.scss";
import "./sflex.scss";
import "../style.scss";

import { blueArrow, sflexhero, sflexlogo, sFlexPdf } from "../../../images";
import Dash from "../../../components/Dash/Dash";
import ProductsPopup from "../../../components/ProductsPopup/ProductsPopup";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const SFlex = () => {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const currentURL = window.location.href;

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={currentURL}
        metaTitle="S-flex is a groundbreaking self-stretch polyester filament yarn - Sanathan Textiles"
        metaDesc="S-flex offers four-way stretch capabilities without spandex. This means you can enjoy the same stretch and comfort as traditional stretch fabrics at an even lighter weight , with a more natural and breathable feel."
      />

      {isModalOpen && <ProductsPopup onRequestClose={toggleModal} />}
      <section className="products_inner">
        <div className="wrapper">
          <div className="breadcrumb_styles">Products {">"} S Flex</div>
          <div className="left_section">
            <p className="hollow_title">Products</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <img className="drycool_img" src={sflexlogo} alt="sanathan img" />
            <p>
              S-flex is a groundbreaking self-stretch polyester filament yarn.
              It offers four-way stretch capabilities without spandex. This means
              you can enjoy the same stretch and comfort as traditional stretch
              fabrics at an even lighter weight, with a more natural and
              breathable feel. This innovative yarn promises cost-effective,
              easy-to-handle fabric production with superior performance across
              knitted, woven and warp knitted fabrics.
            </p>
            <div className="bottom_arrow_container broch_link_wrap">
              <a
                href={sFlexPdf}
                target="_blank"
                rel="noreferrer"
                className="broch_link"
              >
                Download Brochure
                <img
                  className="right_arrow"
                  src={blueArrow}
                  alt="sanathan img"
                />
              </a>
            </div>
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={sflexhero} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="sflex_sec3">
        <div className="wrapper">
          <h3>Advantages:</h3>
          <div className="row">
            <div className="col-lg-4">
              <ul>
                <li>Spandex-Free Four way Stretch</li>
                <li>Versatile Applications</li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>Cost-Effective Processing</li>
                <li>Dimensional Stability</li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>Enhanced Fabric Properties</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SFlex;

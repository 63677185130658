import { useEffect, useState } from "react";
import "../ProductsInner.scss";
import "./cdp.scss";
import "../style.scss";

import { blueArrow, cdpDigitalPdf, cdphero, cdplogo } from "../../../images";
import Dash from "../../../components/Dash/Dash";
import ProductsPopup from "../../../components/ProductsPopup/ProductsPopup";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const CDP = () => {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const currentURL = window.location.href;

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={currentURL}
        metaTitle="Cationic Dyeable Polyester (CDP) - Sanathan Textiles"
        metaDesc="Cationic Dyeable Polyester (CDP) is a specialised polyester yarn that can be coloured with cationic dyes. It shows excellent colour development and fastness and hence CDP yarns enhances dyeability and results in more vibrant and long-lasting colours."
      />

      {isModalOpen && <ProductsPopup onRequestClose={toggleModal} />}
      <section className="products_inner">
        <div className="wrapper">
          <div className="breadcrumb_styles">
            Products {">"} Cationic Dyeable Polyester
          </div>
          <div className="left_section">
            <p className="hollow_title">Products</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <img className="drycool_img" src={cdplogo} alt="sanathan img" />
            <p>
              Cationic Dyeable Polyester (CDP) is a specialised polyester yarn
              that can be coloured with cationic dyes. It shows excellent colour
              development and fastness and hence CDP yarns enhances dyeability
              and results in more vibrant and long-lasting colours.
            </p>
            <div className="bottom_arrow_container broch_link_wrap">
              <a
                href={cdpDigitalPdf}
                target="_blank"
                rel="noreferrer"
                className="broch_link"
              >
                Download Brochure
                <img
                  className="right_arrow"
                  src={blueArrow}
                  alt="sanathan img"
                />
              </a>
            </div>
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={cdphero} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>

      <section className="cdp_sec3">
        <div className="wrapper">
          <h3>Advantages:</h3>
          <div className="row">
            <div className="col-lg-4">
              <ul>
                <li>Excellent dye affinity</li>
                <li>
                  Exhibit superior resistance to colour fading when exposed to
                  water.
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>High luminosity</li>
                <li>
                  The anionic groups in CDP enhance dye absorption, leading to
                  better colour uniformity and depth.
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul>
                <li>
                  Excellent colourfastness (Ability to hold vibrant colours)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CDP;

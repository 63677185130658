import { useEffect, useState } from "react";
import "../ProductsInner.scss";

import {
  blueArrow,
  coloredArrow,
  dryCoolPdf,
  Polyester,
  Polyester1,
  Polyester2,
  Polyester3,
  Polyester4,
  Polyester5,
} from "../../../images";
import Dash from "../../../components/Dash/Dash";
import ProductsPopup from "../../../components/ProductsPopup/ProductsPopup";
import Popsecond from "../../../components/Popsecond/Popsecond";
import Popthird from "../../../components/Popthird/Popthird";
import Popfour from "../../../components/Popfour/Popfour";
import Popfive from "../../../components/Popfive/Popfive";
import MetaDecorator from "../../../components/MetaDecorator/MetaDecorator";

const PolyesterYarn = () => {
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [isModalOpen2, setModalIsOpen2] = useState(false);
  const [isModalOpen3, setModalIsOpen3] = useState(false);
  const [isModalOpen4, setModalIsOpen4] = useState(false);
  const [isModalOpen5, setModalIsOpen5] = useState(false);
  const currentURL = window.location.href;

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
  };
  const toggleModal2 = () => {
    setModalIsOpen2(!isModalOpen2);
  };
  const toggleModal3 = () => {
    setModalIsOpen3(!isModalOpen3);
  };
  const toggleModal4 = () => {
    setModalIsOpen4(!isModalOpen4);
  };
  const toggleModal5 = () => {
    setModalIsOpen5(!isModalOpen5);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={currentURL}
        metaTitle="Polyester Yarn Manufacturers & Suppliers in India | Sanathan Textiles"
        metaDesc="Discover high-quality polyester yarn from one of the top manufacturers & suppliers in India. Our range includes partially oriented yarn, fully drawn yarn, etc., for all needs."
      />

      {isModalOpen && <ProductsPopup onRequestClose={toggleModal} />}
      {isModalOpen2 && <Popsecond onRequestClose={toggleModal2} />}
      {isModalOpen3 && <Popthird onRequestClose={toggleModal3} />}
      {isModalOpen4 && <Popfour onRequestClose={toggleModal4} />}
      {isModalOpen5 && <Popfive onRequestClose={toggleModal5} />}
      <section className="products_inner" data-aos="fade-up">
        <div className="wrapper">
          <div className="breadcrumb_styles">Products {">"} Polyester yarns</div>
          <div className="left_section">
            <p className="hollow_title">Products</p>
            <div className="dash_wrapper">
              <Dash />
            </div>
            <h1 className="yarns">Polyester yarns</h1>
            <h4 className="capacity">2,00,000+ MTPA Manufacturing capacity</h4>
            <p>
              Our polyester division is a fully integrated setup where our input
              is petrochemicals to produce different types of polyester yarns.
              <br />
              <br />
              The polymerization setup with 3 lustres gives us the flexibility
              to meet the customer's every Yarn product need.
              <br />
              <br />
              Keenly focused on Born coloured Dyed Yarns, Functional Yarns and
              Made To Order (Customised) Yarns and other value-added products.
              <br />
              <br />
              The polyester division has a wide variety to offer customers under
              Partially Oriented Yarn (POY), Fully Drawn Yarn (FDY), Draw
              Textured Yarn (DTY), Air Textured Yarn (ATY) & Twisted Yarn types.
            </p>
            <img src={coloredArrow} className="arrow" alt="sanathan img" />
            {/* <div className="bottom_arrow_container broch_link_wrap">
              <a
                href={dryCoolPdf}
                target="_blank"
                rel="noreferrer"
                className="broch_link"
              >
                Download Brochure
                <img
                  className="right_arrow"
                  src={blueArrow}
                  alt="sanathan img"
                />
              </a>
            </div> */}
          </div>
          <div className="right_section">
            <figure className="bg_img">
              <img src={Polyester} alt="sanathan img" />
            </figure>
          </div>
        </div>
      </section>
      <section className="products_inner_list" data-aos="fade-up">
        <div className="wrapper">
          <div className="products_inner_list_heading_wrapper">
            <div className="hollow_title">Product list</div>
            <h3>
              Click on any product to view <br /> detailed information
            </h3>
          </div>
          <div className="products_inner_listing">
            <div className="product_box">
              <button onClick={toggleModal}>
                <figure>
                  <img src={Polyester1} alt="sanathan img" />
                </figure>
                <p>Partially Oriented Yarn</p>
              </button>
            </div>
            <div className="product_box">
              <button onClick={toggleModal2}>
                <figure>
                  <img src={Polyester2} alt="sanathan img" />
                </figure>
                <p>Fully Drawn Yarn</p>
              </button>
            </div>
            <div className="product_box">
              <button onClick={toggleModal3}>
                <figure>
                  <img src={Polyester3} alt="sanathan img" />
                </figure>
                <p>Draw Textured/Texturized Yarn</p>
              </button>
            </div>
            <div className="product_box">
              <button onClick={toggleModal4}>
                <figure>
                  <img src={Polyester4} alt="sanathan img" />
                </figure>
                <p>Air Textured Yarn</p>
              </button>
            </div>
            <div className="product_box">
              <button onClick={toggleModal5}>
                <figure>
                  <img src={Polyester5} alt="sanathan img" />
                </figure>
                <p>Twisted Yarns</p>
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="products_inner_watch_now">
        <div className="wrapper">
          <div className="hollow_title">Watch Now</div>
          <figure>
            <img src={ytthumb} alt="sanathan img"/>
          </figure>
        </div>
      </section> */}
    </>
  );
};

export default PolyesterYarn;

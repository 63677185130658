import { useEffect, useState } from "react";
import "./SalesEnquiry.css";
// import "./SalesEnquiry.module.css";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TextField from "@mui/material/TextField";
import MuiPhoneNumber from "material-ui-phone-number";

import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";

import { coloredArrow } from "../../images";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const SalesEnquiry = ({ onRequestClose }) => {
  // const notify = () =>
  //   toast(
  //     "Thank you. Your message has been received, we will update you shortly."
  //   );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [selectOption, setSelectOption] = useState("");
  const [salesEnquiryDetails, setSalesEnquiryDetails] = useState({
    full_name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [salesEnquiryDetailsErr, setSalesEnquiryDetailsErr] = useState({
    fullNameErr: "",
    emailErr: "",
    phoneErr: "",
    messageErr: "",
  });

  const { full_name, email, phone, message } = salesEnquiryDetails;

  const handleFormFieldsErr = (errField, message) => {
    setSalesEnquiryDetailsErr((prevState) => {
      return {
        ...prevState,
        [errField]: message,
      };
    });
  };

  const emailRegexPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const alphaCharRegex = /^[a-zA-Z ]*$/;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let formIsValid = true;

    if (!full_name.trim()) {
      formIsValid = false;
      handleFormFieldsErr("fullNameErr", "*This field is required");
    } else if (!full_name.match(alphaCharRegex)) {
      formIsValid = false;
      handleFormFieldsErr(
        "fullNameErr",
        "*Please enter alphabet characters only."
      );
    } else {
      handleFormFieldsErr("fullNameErr", "");
    }

    if (!email.trim()) {
      formIsValid = false;
      handleFormFieldsErr("emailErr", "This field is required");
    } else if (!emailRegexPattern.test(email)) {
      formIsValid = false;
      handleFormFieldsErr("emailErr", "*Please enter valid email-Id.");
    } else {
      handleFormFieldsErr("emailErr", "");
    }

    if (!phoneNumber) {
      formIsValid = false;
      handleFormFieldsErr("phoneErr", "*This field is requried");
    } else if (phoneNumber.length > 13) {
      console.log(phoneNumber.length);
      formIsValid = false;
      handleFormFieldsErr("phoneErr", "*Please enter valid mobile number.");
    } else if (!isPossiblePhoneNumber(phoneNumber.toString())) {
      formIsValid = false;
      handleFormFieldsErr("phoneErr", "*Please enter valid mobile number.");
    } else {
      handleFormFieldsErr("phoneErr", "");
    }
    // if (!message) {
    //     formIsValid = false;
    //     handleFormFieldsErr("messageErr", "*This field is requried");
    // } else {
    //     handleFormFieldsErr("messageErr", "");
    // }
    if (formIsValid) {
      // onRequestClose();
      setIsSubmitting(true);
      formSubmit();
    }

    console.log(salesEnquiryDetails);
  };

  const formSubmit = async () => {
    try {
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/sales`,
        data: salesEnquiryDetails,
      });
      if (data.status === 200) {
        setSalesEnquiryDetails({
          full_name: "",
          email: "",
          phone: "",
          message: "",
        });
        setPhoneNumber("");
        toast.success(
          "Thank you. Your message has been received, we will update you shortly.",
          {
            autoClose: 3000,
          }
        );
        setTimeout(() => {
          onRequestClose();
        }, 3100);
        setIsSubmitting(false);
      } else {
        setIsSubmitting(false);
        console.log(data.message);
        // toast.error(data.message, {
        //     autoClose: 5000,
        // });
      }
    } catch (error) {
      // toast.error("Something went wrong", {
      //     autoClose: 5000,
      // });
      console.log("sales enquiry form error:-", error);
    }
  };

  const handleInput = (event) => {
    const { name, value } = event.target;

    setSalesEnquiryDetails((preValue) => {
      return {
        ...preValue,
        [name]: value,
        phone: phoneNumber,
      };
    });
  };

  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        onRequestClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  const handleChange = (event) => {
    setSelectOption(event.target.value);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <section className="popup_form_wrapper_sales">
        <div className="salesEnquiry">
          <div className="close_button">
            <button
              type="button"
              onClick={onRequestClose}
              className="btn-close btn-close-sales"
            >
              <span className="icon-cross"></span>
              {/* <span className="visually-hidden">Close</span> */}
              {/* <span aria-hidden="true">&times;</span> */}
            </button>
          </div>
          <div className="wrapper">
            <h3> Contact Us</h3>
            <p>Ask a question and we will get back to you shortly.</p>
            <div className="form_wrapper">
              <form onSubmit={handleFormSubmit} id="sales_form">
                <div className="form_input_wrapper form-group">
                  <TextField
                    type="text"
                    name="full_name"
                    label="Full Name*"
                    variant="standard"
                    value={salesEnquiryDetails.full_name}
                    onChange={handleInput}
                    // required
                  />
                  <div className="err">
                    {salesEnquiryDetailsErr.fullNameErr}
                  </div>
                </div>
                <div className="form_input_wrapper form-group">
                  <TextField
                    type="email"
                    name="email"
                    label="Email Id*"
                    variant="standard"
                    value={salesEnquiryDetails.email}
                    onChange={handleInput}
                    // required
                  />
                  <div className="err">{salesEnquiryDetailsErr.emailErr}</div>
                </div>
                <div className="form_input_wrapper contact_wrapper form-group">
                  {/* <MuiPhoneNumber
                                        defaultCountry={'in'}
                                        name="phone"
                                        placeholder="Phone"
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                        countryCodeEditable={false}
                                        // required
                                    /> */}
                  <PhoneInput
                    style={{ backgroundColor: "white" }}
                    defaultCountry="IN"
                    placeholder="Phone*"
                    international
                    countryCallingCodeEditable={false}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                  />
                </div>
                <div className="err">{salesEnquiryDetailsErr.phoneErr}</div>

                <div className="form_input_wrapper form-group contact_select_wrapper">
                  <FormControl variant="standard" sx={{ margin: 0 }}>
                    <InputLabel id="dare_you" sx={{ margin: 0 }}>
                      Let us know more about you
                    </InputLabel>
                    <Select
                      className="are_you_select"
                      labelId="are_you"
                      value={selectOption}
                      onChange={handleChange}
                      label="Let us know more about you"
                      sx={{
                        ".MuiInput-input:focus": {
                          backgroundColor: "#ffffff",
                        },
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      // InputLabelProps={{
                      // style: {
                      //   fontSize: "1.2em",
                      // },
                      // }}
                    >
                      <MenuItem value={1}>Manufacturer</MenuItem>
                      <MenuItem value={2}>Retailer</MenuItem>
                      <MenuItem value={3}>Seller</MenuItem>
                      <MenuItem value={4}>⁠Investor</MenuItem>
                      <MenuItem value={5}>Media and PR</MenuItem>
                    </Select>
                  </FormControl>
                  <div className="err">{salesEnquiryDetailsErr.phoneErr}</div>
                </div>

                <div className="form_input_wrapper form-group message_wrapper">
                  <TextField
                    type="text"
                    name="message"
                    label="Message"
                    variant="standard"
                    value={salesEnquiryDetails.message}
                    onChange={handleInput}
                    // required
                  />
                  <div className="err">{salesEnquiryDetailsErr.messageErr}</div>
                </div>
                <div className="submit_button_container">
                  <button
                    type="submit"
                    className="sanathan_btn sanathan_actual_btn"
                    id="sales_form_submit_btn"
                  >
                    <span
                      className="download_btn_text"
                      disabled={isSubmitting ? true : false}
                    >
                      {isSubmitting ? "Submitting..." : "submit"}
                    </span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan button arrow"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SalesEnquiry;

import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  facebook,
  gallery1,
  moreblog1,
  moreblog2,
  moreblog3,
  playicon,
  whatsapp,
  twitterblog,
  copy,
  blogimg9,
} from "../../images";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Link } from "react-router-dom";
//utils
import { reduceDescSize } from "../../helpers/utils";

import "./BlogInner9.scss";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

const BlogInner9 = () => {
  const { width } = useWindowDimensions();
  const [copied, setCopied] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [vidLink, setVidLink] = useState("");

  const blogDateArr = [
    {
      date: "June 2, 2022",
      category: "Lorem Ipsum",
      company: "Sanathan",
    },
  ];

  const blogDescArr = [
    {
      id: 1,
      heading: "The Future of Polyester Yarns: Trends and Innovation",
      desc1: `
      <a href="https://www.sanathan.com/products/polyester-yarn">Polyester yarn</a> is emerging as a key player in the future of textiles. According to reports by the Brainy Insights, the Yarn Market is projected to grow at a CAGR of 5.9% during the forecast period, driven by increasing demand for innovative and functional textile yarns. These international industrial studies, compiled by skilled analysts, offer insights into key market trends, demand generators, trade dynamics, leading companies, and emerging themes.
      <br/><br/>
      At the forefront of this evolution, Sanathan Functional and Value-Added Yarns exemplify cutting-edge innovation in polyester yarn manufacturing. Products like Stretch Yarn, designed for enhanced flexibility and resilience, cater to high-performance applications.
      <br/><br/>
      Meanwhile, DryCool Yarn provides superior moisture-wicking properties, ensuring comfort in activewear and athleisure fabrics. The revolutionary S-Flex Yarn offers an unmatched combination of elasticity and strength, making it ideal for durable yet adaptive textiles.
      <br/><br/>
      <strong>Sustainable Yarns</strong>
      <br/><br/>
      Innovative solutions for more sustainable systems are being developed worldwide, with two significant approaches gaining traction: closed-loop systems and circular fashion.
      <br/><br/>
      <strong>Incorporation of the Recycling Process</strong>
      <br/><br/>
      We see a major surge in the advancements of recycling methods and a prominent change in the production of polyester yarn. They are recycled, collecting the disposed polyester products, breaking them down into their core components, and using the remnants to create new polyester fibers. It is a primitive approach to reduce greenhouse gas emissions while conserving natural resources by minimizing the need to produce virgin polyester.
      <br/><br/>
      It has established  sustainable corridors for the apparel manufacturers enabling a green source of materials that would otherwise end up in landfills. They often use recycled polyester from fleece for making fabrics which is usually used by outdoor clothing manufacturers to impact the environment in a positive manner.
      <br/><br/>
      Sustainability is a necessity more than an option. Therefore, polyester recycling is essential for shifting towards an efficient economy where ecology is considered while designing products. The booming industry should significantly focus on the technology and infrastructure to create recyclable products. By embracing recycling, the sector can contribute to a cleaner, more sustainable future.
      <br/><br/>
      <strong>Sanathan Textiles and the Polyester Yarn Revolution</strong>
      <br/><br/>
      We at Sanathan Textiles are dedicated to innovation and embracing sustainability in the polyester yarn industry.  We are committed to reflecting the broader industry’s push towards greener textile solutions with an inclination toward sustainable practices. Our Sanathan Reviro, our <a href="https://www.sanathan.com/products/reviro">recycled yarn</a> initiative explicitly exemplifies our approach to sustainable innovation.
      <br/><br/>
      Our process is maximally energy efficient. We try to reduce carbon footprints in our production by minimizing water and energy consumption during manufacturing. We extend our commitment towards ecology using eco-friendly dyes and processing chemicals, which not only mitigate harmful environmental impacts but also prioritize the health and safety of our workers and consumers.
      <br/><br/>
      Our polyester yarns cater to a wide range of industries, including apparel, home textiles, and industrial applications. By ensuring that our products meet both performance and sustainability standards, we offer innovative solutions such as high-tenacity polyester yarns and <a href="https://www.sanathan.com/products/dry-cool">moisture-wicking yarns</a> like DryCool. These durable and functional yarns reflect our dedication to combining quality with eco-conscious principles.
      <br/><br/>
      We provide polyester yarns that cater to a wide range of industries, including apparel, home, textiles, and industrial applications. We offer innovative solutions such as high-tenacity polyester yarns and moisture-wicking yarns like DryCool, while ensuring that our products meet both performance and sustainability. We strike a definitive balance between quality with eco-conscious principles.
      <br/><br/>
      <strong>Innovation Meets Functionality</strong>
      <br/><br/>
      Sanathan Textile takes pride in being at the forefront of providing a sustainable future for the polyester yarn industry, demonstrating that functionality and sustainability can exist simultaneously. We are collaboratively reshaping our sector by prioritizing environmental responsibility while maintaining quality. We believe in contemporary industry advancements, utilizing cutting-edge technology and sustainable practices to deliver high-quality products that drive both growth and positive change.
      <br/><br/>
      Surely these innovations would bring the polyester fibers industry in line with market requirements and would answer its responsibilities-toward the environment. Thanks to efforts like these, polyester yarn will have a future that is much more sustainable, innovative, and responsible.
      `,
    },
  ];

  const moreblogsArr = [
    {
      img: moreblog1,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog3,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
    {
      img: moreblog2,
      date: "December 9, 2019",
      category: "Lorem Ipsum",
      title:
        "Deciding on a colour scheme for your kitchen is as much about the size and shape of",
      desc: "Morbi volutpat nisi a ligula vestibulum placerat. Suspendisse venenatis pulvinar nibh sed convallis. Cras elementum nunc a purus sodales tincidunt. Duis fringilla quam at tellus consectetur, id placerat metus tincidunt. In tellus mauris, pellentesque ac est sed, vestibulum hendrerit felis. Pellentesque molestie lorem id placerat condimentum. Integer fermentum arcu at massa vestibulum placerat. Donec placerat suscipit sollicitudin. Nam pretium est sit amet urna semper ullamcorper. Nulla facilisi. Sed pellentesque tempor tempor. Vestibulum tristique pellentesque feugiat. Vivamus in nisl nec quam maximus venenatis. Sed euismod eros bibendum, dignissim erat sed, blandit tellus.",
    },
  ];

  const galleryArr = [
    {
      id: 1,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 2,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 3,
      type: "video",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
    {
      id: 4,
      type: "image",
      image: gallery1,
      title: "Image Name",
      ytLink: "https://www.youtube.com/embed/HHjSdy9l7Kc",
    },
  ];

  useEffect(() => {
    var gallery_swiper_container = new Swiper(
      ".swiper-container.gallery_swiper_container",
      {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          prevEl: ".bloginner_sec2 .left_arrow",
          nextEl: ".bloginner_sec2 .right_arrow",
        },
        breakpoints: {
          767: {
            slidesPerView: 1.6,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 2.7,
            spaceBetween: 10,
          },
        },
        // loop: true,
        // centeredSlides: true,
        // loopedSlides: thumbArr.length, //how many slides it should duplicate
      }
    );
    var moreblogs_swiper_container = new Swiper(
      ".swiper-container.moreblogs_swiper_container",
      {
        slidesPerView: 3,
        spaceBetween: 40,
        loop: false,
        navigation: {
          prevEl: ".bloginner_sec3 .left_arrow",
          nextEl: ".bloginner_sec3 .right_arrow",
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          767: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      }
    );
  });

  useEffect(() => {
    if (copied) {
      let copiedTimeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => {
        clearTimeout(copiedTimeout);
      };
    }
  }, [copied]);

  const dateList = blogDateArr.map((item) => (
    <>
      <p className="blog_date">{item.date} | </p>
      <p className="blog_category"> {item.category} </p>
      <p className="blog_company">| {item.company}</p>
    </>
  ));

  const descList = blogDescArr.map((item) => (
    <>
      <p
        className="blog_desc"
        dangerouslySetInnerHTML={{ __html: item.desc1 }}
      />
    </>
  ));

  const galleryVideoImageList = galleryArr.length
    ? galleryArr.map((item, i) => (
        <div className="swiper-slide" key={i}>
          {item.type == "video" ? (
            <>
              <div
                className="video_wrapper_div"
                onClick={() => handleImageWrapperClick(item.ytLink)}
              >
                <div className="content">
                  <img
                    src={item.image}
                    alt="Sanathan video gallery img"
                    loading="lazy"
                    className="gallery_img"
                  />
                  <div className="content-overlay"></div>
                  <img
                    src={playicon}
                    alt="Sanathan coloredArrow img"
                    loading="lazy"
                    className="playicon"
                    onClick={() => handleImageWrapperClick(item.ytLink)}
                  />
                  {/* <h2 className="galleryImageName">{item.title}</h2> */}
                </div>

                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          ) : (
            <>
              <div className="content">
                <img
                  src={item.image}
                  alt="Sanathan blog gallery img"
                  loading="lazy"
                  className="gallery_img"
                />
                <div className="content-overlay"></div>
                {/* <h2 className="galleryImageName">{item.title}</h2> */}
              </div>
            </>
          )}
        </div>
      ))
    : null;

  const moreblogsList = moreblogsArr.map((item, i) => (
    <>
      <li className="swiper-slide moreblog_detail_wrapper">
        <Link to="/blog-inner" key={i}>
          <div className="imageanimate zoom-out">
            <img
              src={item.img}
              alt="Sanathan our blog img"
              loading="lazy"
              className="moreblog_img"
            />
            {/* <div className="content-overlay"></div> */}
          </div>
          <div className="text_container">
            <h1 className="moreblog_date">{item.date}</h1>
            <div className="verticle_line"> | </div>
            <h1 className="moreblog_category"> {item.category}</h1>
            <h1 className="moreblog_name">{item.title}</h1>
            <p className="moreblog_synopsis">
              {reduceDescSize(item.desc, 100)}
            </p>
            <div className="moreblog_cta_wrapper"></div>
          </div>
        </Link>
      </li>
    </>
  ));

  const handleImageWrapperClick = (videoLink) => {
    setToggler(!toggler);
    setVidLink(videoLink);
  };

  const copyUrl = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator
        canonicalLink={window.location.href}
        metaTitle="The Future of Polyester Yarn in Textile and Clothing - Sanathan Textiles"
        metaDesc="Explore groundbreaking advancements and evolving trends in polyester yarns. Learn how innovation and sustainability are shaping the future at Sanathan Textiles."
      />
      <section className="bloginner_sec1" data-aos="fade-in">
        <div className="wrapper">
          <img
            className="mainimg"
            src={blogimg9}
            alt="Sanathan blog inner img"
          />
          <div className="row social_icon_date">
            <div className="col-md-6 col-lg-4 datecat_list">
              {/* {dateList} */}
            </div>
            <div className="col-md-1 col-lg-4"></div>
            <div className="col-md-5 col-lg-4">
              {width < 767 ? <h2 className="share_title">Share</h2> : null}
              <div className="social_main_wrapper">
                <ul className="social_icons">
                  {width > 767 ? (
                    <li className="social_icon_li">
                      <h2 className="share_title">Share</h2>
                    </li>
                  ) : null}
                  <li className="social_icon_li">
                    <FacebookShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={facebook}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </FacebookShareButton>
                  </li>
                  <li className="social_icon_li">
                    <WhatsappShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={whatsapp}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </WhatsappShareButton>
                  </li>
                  <li className="social_icon_li">
                    <TwitterShareButton
                      url={`${
                        window.location.href ? window.location.href : null
                      }`}
                    >
                      <span className="social_icon">
                        <img
                          src={twitterblog}
                          alt="Sanathan facebook logo"
                          loading="lazy"
                        />
                      </span>
                    </TwitterShareButton>
                  </li>
                  <li
                    className="social_icon_li social_icon_li_copy"
                    onClick={copyUrl}
                  >
                    <span className="social_icon">
                      <img
                        src={copy}
                        alt="Sanathan copy url logo"
                        loading="lazy"
                      />
                    </span>
                    <span className="social_name">
                      {!copied ? "" : "Copied!"}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="horizontal_line"></div>
          <h1 className="blog_inner_name">{blogDescArr[0].heading}</h1>
          <div>{descList}</div>
        </div>
      </section>
    </>
  );
};

export default BlogInner9;

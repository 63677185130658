import * as React from "react";
import { useEffect, useState } from "react";
import "./Careers.css";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TextField from "@mui/material/TextField";

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";

import { coloredArrow, uploadlogo } from "../../images";
import MetaDecorator from "../MetaDecorator/MetaDecorator";

const Careers = ({ onRequestClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const notify = () => toast("Form submitted successfully!!");
  const [phoneNumber, setPhoneNumber] = useState();
  const [resumeValue, setResumeValue] = useState("");
  const [resumeName, setResumeName] = useState("");
  const [careersDetails, setCareersDetails] = useState({
    name: "",
    email: "",
    phone: "",
    position: "",
    resume: "",
    message: "",
  });

  const [careersDetailsErr, setCareersDetailsErr] = useState({
    fullNameErr: "",
    emailErr: "",
    phoneErr: "",
    positionErr: "",
    resumeErr: "",
    messageErr: "",
  });

  // const [department, setDepartment] = React.useState('');

  // const handleChange = (event) => {
  //     setDepartment(event.target.value);
  // };

  const handleResumeChange = (event) => {
    const file = event.target.files[0];
    setResumeName(file.name);
    setResumeValue(file);
    handleFormFieldsErr("resumeErr", "");
  };

  const { name, email, phone, position, resume, message } = careersDetails;

  const handleFormFieldsErr = (errField, message) => {
    setCareersDetailsErr((prevState) => {
      return {
        ...prevState,
        [errField]: message,
      };
    });
  };

  const emailRegexPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const alphaCharRegex = /^[a-zA-Z ]*$/;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let formIsValid = true;

    if (!name.trim()) {
      formIsValid = false;
      handleFormFieldsErr("fullNameErr", "*This field is required");
    } else if (!name.match(alphaCharRegex)) {
      formIsValid = false;
      handleFormFieldsErr(
        "fullNameErr",
        "*Please enter alphabet characters only."
      );
    } else {
      handleFormFieldsErr("fullNameErr", "");
    }

    if (!email.trim()) {
      formIsValid = false;
      handleFormFieldsErr("emailErr", "This field is required");
    } else if (!emailRegexPattern.test(email)) {
      formIsValid = false;
      handleFormFieldsErr("emailErr", "*Please enter valid email-Id.");
    } else {
      handleFormFieldsErr("emailErr", "");
    }

    if (!phoneNumber) {
      formIsValid = false;
      handleFormFieldsErr("phoneErr", "*This field is requried");
    } else if (phoneNumber.length > 13) {
      console.log(phoneNumber.length);
      formIsValid = false;
      handleFormFieldsErr("phoneErr", "*Please enter valid mobile number.");
    } else if (!isPossiblePhoneNumber(phoneNumber.toString())) {
      formIsValid = false;
      handleFormFieldsErr("phoneErr", "*Please enter valid mobile number.");
    } else {
      handleFormFieldsErr("phoneErr", "");
    }

    if (!position.trim()) {
      formIsValid = false;
      handleFormFieldsErr("positionErr", "*This field is required");
    } else {
      handleFormFieldsErr("positionErr", "");
    }

    console.log("resume:-", resumeValue);

    if (!resumeValue) {
      formIsValid = false;
      handleFormFieldsErr("resumeErr", "*This field is requried");
    } else {
      let fileName = resumeValue.name;
      let fileSize = resumeValue.size;
      let fileExtension = fileName.split(".").pop();
      console.log("fileExtension", fileExtension);
      if (!["pdf", "doc", "docx"].includes(fileExtension)) {
        formIsValid = false;
        handleFormFieldsErr("resumeErr", "Invalid file format.");
      } else if (fileSize >= 2000000) {
        formIsValid = false;
        handleFormFieldsErr(
          "resumeErr",
          "Please upload file smaller than or equal to 2 MB."
        );
      } else {
        console.log(formIsValid);
        handleFormFieldsErr("resumeErr", "");
      }
    }

    // if (!message) {
    //     formIsValid = false;
    //     handleFormFieldsErr("messageErr", "*This field is requried");
    // } else {
    //     handleFormFieldsErr("messageErr", "");
    // }
    if (formIsValid) {
      // notify();
      // setTimeout(() => {
      //   onRequestClose();
      // }, 10000);
      setIsSubmitting(true);
      formSubmit();
      // console.log("yes form perfect:- ", careersDetails);
    }
    // console.log(formIsValid)
  };

  const formSubmit = async () => {
    try {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("position", position);
      formData.append("resume", resumeValue);
      formData.append("message", message);

      console.log("this is form data", formData);

      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/career`,
        data: formData,
      });
      if (data.status === 200) {
        setCareersDetails({
          name: "",
          email: "",
          phone: "",
          position: "",
          message: "",
        });
        setResumeName("");
        setResumeValue("");
        setPhoneNumber("");
        toast.success(
          "Thank you. Your message has been received, we will update you shortly.",
          {
            autoClose: 3000,
          }
        );
        setTimeout(() => {
          onRequestClose();
        }, 3100);
        setIsSubmitting(false);
      } else {
        console.log(data.message);
        // toast.error(data.message, {
        //     autoClose: 5000,
        // });
        setIsSubmitting(false);
      }
    } catch (error) {
      // toast.error("Something went wrong", {
      //     autoClose: 5000,
      // });
      setIsSubmitting(false);
      console.log("contact us form error:-", error);
    }
  };

  const handleInput = (event) => {
    const { name, value } = event.target;

    setCareersDetails((preValue) => {
      return {
        ...preValue,
        [name]: value,
        phone: phoneNumber,
        resume: resumeValue,
      };
    });
  };

  useEffect(() => {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        onRequestClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  return (
    <>
      <MetaDecorator
        canonicalLink="https://www.sanathan.com/career"
        metaTitle="Careers | Sanathan Textiles"
        metaDesc="Sanathan Textiles is always on the lookout for brilliant minds. Fill in the form with your resume and apply now!"
      />

      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <section className="popup_form_wrapper_career">
        <div className="careers">
          <div className="close_button">
            <button
              type="button"
              onClick={onRequestClose}
              className="btn-close btn-close-careers"
            >
              <span className="icon-cross"></span>
              {/* <span className="visually-hidden">Close</span> */}
              {/* <span aria-hidden="true">&times;</span> */}
            </button>
          </div>
          <div className="wrapper">
            <h3>Careers</h3>
            <p>
              Fill the form to apply for career opportunity at Sanathan Textiles
            </p>
            <div className="form_wrapper">
              <form onSubmit={handleFormSubmit} id="career_form">
                <div className="form_input_wrapper form-group">
                  <TextField
                    type="text"
                    name="name"
                    label="Full Name*"
                    variant="standard"
                    value={careersDetails.name}
                    onChange={handleInput}
                    InputLabelProps={{
                      style: { fontSize: "1.2em" },
                    }}
                  />
                  <div className="err">{careersDetailsErr.fullNameErr}</div>
                </div>
                <div className="form_input_wrapper form-group">
                  <TextField
                    type="text"
                    name="email"
                    label="Email Id*"
                    variant="standard"
                    value={careersDetails.email}
                    onChange={handleInput}
                    InputLabelProps={{
                      style: { fontSize: "1.2em" },
                    }}
                  />
                  <div className="err">{careersDetailsErr.emailErr}</div>
                </div>
                <div className="form_input_wrapper contact_wrapper form-group">
                  {/* <MuiPhoneNumber
                                        defaultCountry={'in'}
                                        name="phone"
                                        placeholder="Phone"
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                        
                                    /> */}
                  <PhoneInput
                    style={{ backgroundColor: "white" }}
                    defaultCountry="IN"
                    placeholder="Phone*"
                    international
                    countryCallingCodeEditable={false}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                  />
                </div>
                <div className="err">{careersDetailsErr.phoneErr}</div>
                <div className="form_input_wrapper form-group select_department">
                  <TextField
                    type="text"
                    name="position"
                    label="Applying position for?*"
                    variant="standard"
                    value={careersDetails.position}
                    onChange={handleInput}
                    InputLabelProps={{
                      style: { fontSize: "1.2em" },
                    }}
                  />
                  {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: '1.2em' }}>Select Department</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={department}
                                            onChange={handleChange}
                                            label="Select Department"
                                            InputLabelProps={{
                                                style: { fontSize: '1.2em' },
                                            }}
                                            
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={1} style={{ fontSize: '1em' }}>One</MenuItem>
                                            <MenuItem value={2} style={{ fontSize: '1em' }}>Two</MenuItem>
                                            <MenuItem value={3} style={{ fontSize: '1em' }}>Three</MenuItem>
                                        </Select>
                                    </FormControl> */}
                  <div className="err">{careersDetailsErr.positionErr}</div>
                </div>
                <div className="form_input_wrapper form-group input_upload_group">
                  <div className="file_container">
                    <div className="upload_text">Upload resume or CV*</div>
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      name="resume"
                      id="upload_resume"
                      hidden
                      onChange={handleResumeChange}
                    />
                    <label for="upload_resume" className="upload_resume_box">
                      <img src={uploadlogo} alt="sanathan upload logo" />
                      <span>Choose File</span>
                    </label>
                    <div className="file_format_wrapper">
                      <span>File format: PDF, DOC, DOCX</span>
                      <span>File size: Less than 2 MB</span>
                    </div>
                    <div className="file_format_wrapper">{resumeName}</div>
                    <span className="err">{careersDetailsErr.resumeErr}</span>
                  </div>
                  {/* <label>Upload Resume or CV*</label>
                  <div className="main_file_wrapper">
                    <span className="upload_img">
                      <img src={uploadImg} />
                    </span>
                    <span className="choose_file_text">Choose File</span>
                    <input
                      type="file"
                      name="resume"
                      accept=".pdf"
                      onChange={handleResumeChange}
                      // value={resumeValue}
                    />
                  </div>
                  <div className="additional_text">
                    <p>File Format : PDF</p>
                    <p>File Size: Less than 2 MB</p>
                  </div>
                  <div className="err">{careersDetailsErr.resumeErr}</div> */}
                </div>
                <div className="form_input_wrapper form-group">
                  <TextField
                    type="text"
                    name="message"
                    label="Message"
                    variant="standard"
                    value={careersDetails.message}
                    onChange={handleInput}
                    InputLabelProps={{
                      style: { fontSize: "1.2em" },
                    }}
                  />
                  <div className="err">{careersDetailsErr.messageErr}</div>
                </div>
                <div className="submit_button_container">
                  <button
                    type="submit"
                    className="sanathan_btn sanathan_actual_btn"
                    disabled={isSubmitting ? true : false}
                    id="career_form_submit_btn"
                  >
                    <span className="download_btn_text">
                      {isSubmitting ? "Submitting..." : "submit"}
                    </span>
                    <img
                      src={coloredArrow}
                      className="btn_arrow"
                      alt="sanathan button arrow"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
